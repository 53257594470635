:root{
  --yellow: #efb528;
  --white: white;
  --black: #222525;
  --font-family: 'Libre Baskerville', serif;

  --breakpoint-sm: 360px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1129px;
  
}

body{
  background-image: url("../src/assets/background.png");
  background-position: 0px 0px;
  background-size: contain;
  background-attachment: scroll;
  box-sizing: border-box;

}

.header{
  display: flex;
  background-color: var(--black);
  flex-direction: column;
  align-items: center;
  padding: 24px;
}

.page-title{
  color: var(--white);
  font-family: var(--font-family);
  text-align: center;
  line-height: 1;
  font-weight: 400; 
}
.title{
  font-family: var(--font-family);
  line-height: 1;
  font-weight: bold;
  align-self: center;
  text-transform: uppercase;
  text-align: center;
}

#www-logo{
  width: 200px;
}
.main{
  padding: 24px;
  display: flex;
  flex-direction: column;
}


label{
  font-weight: bold;
  color: var(--black);
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: .07em;
  margin-bottom: 8px;
  display: block;
}
.field{
  display: flex;
  flex-direction: column;
  width: 100%;
}
.form{
    padding: 24px!important; 
    max-width: 1024px;
    background-color: lightgray;
    background-clip: border-box;
    border: 1px solid #e4e8f0;
    border-radius: 6px;
    transition-property: all;
    transition-duration: .15s;
    transition-timing-function: ease-in;
    align-self: center;

}

.top-text{
  text-align: center;
  align-self: center;
}
.mr-16{
  margin-right: 16px;
}

button{
  background-color: var(--yellow);
  font-size: 14px;
  font-weight: 600;
  padding: 8px 12px;
  border-radius: 6px;
  border: none;
  cursor: pointer;
}
button:hover{
  background-color: #dda724;
}
input{
    text-indent: 24px;
    max-height: 45px;
    line-height: 1.4;
    color: var(--white);
    font-size: 16px;
    font-weight: 400;
    border: 1px solid #e4e8f0;
    border-radius: 6px;
    display: block;
    width: 100%;
    color: var(--black);
    background-color: var(--white);
    padding: 12px 0;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    transition: ease-in;
    outline: none;
    overflow: auto;
    overflow-wrap: break-word;
    resize: none;
    transition-property: box-shadow,color,background-color;
    transition-duration: .3s;
    transition-timing-function: cubic-bezier(.64,0,.35,1);
    overflow: hidden;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input:disabled{
  background-color: white;
  color: black;
}

.disclosure{
  font-size: 12px;
  color: var(--black);
  text-align: center;
  padding: 24px;
  max-width: 960px;
  align-self: center;
}

.form{
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-gap: 16px;
}

.group1,
.group2, 
.group3 {
 display: grid;
 grid-gap: 16px;
}
.group4{
  display: flex;
  justify-content: space-between;
}

  @media screen and (min-width: 768px) {
    .form{
      grid-template-columns: repeat(3,1fr);
    }
    .group1{
      grid-area: 1/1/2/-1;
      grid-template-columns: 1fr 1fr;
      align-items: flex-end;
    }
    .group2{
      grid-area: 2/1/3/-1;     
      grid-template-columns: 1fr 1fr 1fr;   
      align-items: flex-end;
    }
    .title{
      grid-area: 3/1/4/-1;
    }
    .group3{
      grid-area: 4/1/5/-1;
      grid-template-columns: 1fr 1fr; 
      align-items: flex-end;
    }
  }
